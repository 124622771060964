<template>

  <BlockUI :blocked="loading>0">

    <div class="p-grid">
      <div class="p-col-12 p-md-6">
        <h3><i class="pi pi-globe" style="font-size: 1.6rem"></i> Cadastro de Seguradora</h3>
      </div>

      <div class="p-col-12 p-md-6">
        <Button
          id="buttonGravar"
          label="Gravar"
          icon="pi pi-check"
          style="float: right"
          class="p-mr-2 p-mb-3 p-button-lg p-button-success"
          @click="criar_alterar"
          v-if="$auth.userCan('SEGURADORAS_CADASTRAR')"
        ></Button>
      </div>
    </div>

    <div class="p-grid p-pt-3">
      <div class="p-col-12 p-md-6">

        <!-- Informações Básicas -->
        <div class="card p-fluid">
          <h5 class="p-mb-5"><i class="pi pi-id-card"></i> Identificação</h5>
          <div class="p-grid">

            <div class="p-field p-md-8">
              <label for="nome">Nome</label>
              <InputText
                id="nome"
                type="text"
                v-model="nome"
                maxlength="60"
                :class="(fieldsError.some(v => v === 'nome'))?'p-invalid':''"
              />
            </div>

            <div class="p-field p-md-4">
              <label for="status">Status</label>
              <Dropdown
                id="status"
                v-model="status"
                :options="ativo_inativo"
                optionLabel="nome"
                optionValue="id"
                placeholder="Selecione"
              />
            </div>

            <div class="p-field p-md-4">
              <label for="taxa">Taxa</label>
              <InputNumber
                id="taxa"
                v-model="taxa"
                mode="decimal"
                locale="pt-BR"
                :minFractionDigits="2" 
                :maxFractionDigits="2"
                suffix=" %"
                :min="0.00" :max="100.00"
                :class="
                  fieldsError.some((v) => v === 'taxa')
                    ? 'p-invalid'
                    : ''
                "
                @focus="$utils.inputNumberFocus"
              />
            </div>

            <div class="p-field p-md-4">
              <label for="prioridade">Prioridade</label>
              <InputNumber
                id="prioridade"
                v-model="prioridade"
                :min="0" :max="100"
                :class="
                  fieldsError.some((v) => v === 'prioridade')
                    ? 'p-invalid'
                    : ''
                "
                @focus="$utils.inputNumberFocus"
              />
            </div>

            <div class="p-field p-md-4">
              <label for="integrada">Integração</label>
              <Dropdown
                id="integrada"
                v-model="integrada"
                :options="integrada_ativo_inativo"
                optionLabel="nome"
                optionValue="id"
                placeholder="Selecione"
              />
            </div>

            <div class="p-field p-md-4">
              <label for="analiseNovo">Seguro Novo</label>
              <Dropdown
                id="analiseNovo"
                v-model="analiseNovo"
                :options="integrada_ativo_inativo"
                optionLabel="nome"
                optionValue="id"
                placeholder="Selecione"
              />
            </div>

            <div class="p-field p-md-4">
              <label for="analiseMudancaGarantia">Mudança de Garantia</label>
              <Dropdown
                id="analiseMudancaGarantia"
                v-model="analiseMudancaGarantia"
                :options="integrada_ativo_inativo"
                optionLabel="nome"
                optionValue="id"
                placeholder="Selecione"
              />
            </div>
            
          </div>
        </div>

      </div>

      <div class="p-col-12 p-md-6">

        <!-- Planos -->
        <div class="card p-fluid">
          <h5 class="p-mb-5"><i class="pi pi-money-bill"></i> Planos</h5>
          <div class="p-grid">
            
            <div class="p-field p-md-6">
              <label for="plano_nome">Nome</label>
              <InputText
                id="plano_nome"
                type="text"
                v-model="plano_nome"
                maxlength="60"
                :class="(fieldsError.some(v => v === 'plano_nome'))?'p-invalid':''"
              />
            </div>

            <div class="p-field p-md-3">
              <label for="plano_status">Status</label>
              <Dropdown
                id="plano_status"
                v-model="plano_status"
                :options="ativo_inativo"
                optionLabel="nome"
                optionValue="id"
                placeholder="Selecione"
              />
            </div>

            <div class="p-field p-md-3">
              <label for="plano_status_integracao">Status Integração</label>
              <Dropdown
                id="plano_status_integracao"
                v-model="plano_status_integracao"
                :options="ativo_inativo"
                optionLabel="nome"
                optionValue="id"
                placeholder="Selecione"
              />
            </div>

            <div class="p-field p-md-12">
              <label for="plano_coberturas">Coberturas</label>
              <Textarea
                id="plano_coberturas"
                v-model="plano_coberturas"
                :autoResize="true"
                rows="3"
                cols="30" 
                :class="(fieldsError.some(v => v === 'plano_coberturas'))?'p-invalid':''"
              />
            </div>

            <div class="p-field p-md-12">
              <label for="plano_id_integracao">ID Integação</label>
              <InputText
                id="plano_id_integracao"
                v-model="plano_id_integracao"
                type="text"
                :class="
                  fieldsError.some((v) => v === 'plano_id_integracao')
                    ? 'p-invalid'
                    : ''
                "
              />
            </div>

            <div class="p-field p-md-2">
              <label for="plano_taxa">Taxa</label>
              <InputNumber
                id="plano_taxa"
                v-model="plano_taxa"
                mode="decimal"
                locale="pt-BR"
                :minFractionDigits="2" 
                :maxFractionDigits="2"
                suffix=" %"
                :min="0.00" :max="100.00"
                :class="
                  fieldsError.some((v) => v === 'plano_taxa')
                    ? 'p-invalid'
                    : ''
                "
                @focus="$utils.inputNumberFocus"
              />
            </div>

            <div class="p-field p-md-5">
              <label for="plano_formula">
                Fórmula &nbsp;
                <Button
                  icon="pi pi-info-circle"
                  @click="$refs.op.toggle($event)"
                  aria:haspopup="true"
                  aria-controls="info"
                  class="p-button-link"
                  style="font-weight: bold; color: red; padding: 0; width: 1rem;"
                ></Button>
              </label>
              <InputText
                id="plano_formula"
                type="text"
                v-model="plano_formula"
                maxlength="150"
                :class="(fieldsError.some(v => v === 'plano_formula'))?'p-invalid':''"
              />
            </div>

            <div class="p-field p-md-5">
              <Button
                id="buttonGravarPlano"
                :label="(plano_id)?'Alterar':'Criar'"
                icon="pi pi-check"
                class="p-mt-4 p-button-success"
                style="width: auto"
                @click="gravar_plano"
              ></Button>
              <Button
                v-if="plano_id != ''"
                id="buttonGravarPlano"
                label="Cancelar"
                icon="pi pi-times"
                style="width: auto"
                class="p-mt-4 p-ml-3 p-button-warning"
                @click="cancelar_alteracao_plano"
              ></Button>
            </div>

            <div class="p-field p-md-12" v-if="planos.length > 0">
              <DataTable sortMode="single" :value="planos">
                <Column field="nome" headerClass="colNome" header="Nome"></Column>
                <Column field="taxa" headerClass="colTaxa" header="Taxa">
                  <template #body="slotProps">
                    {{ $utils.formatDecimal(slotProps.data.taxa) }} %
                  </template>
                </Column>
                <Column field="status" headerClass="colStatus" header="Status"></Column>
                <Column field="status_integracao" headerClass="colStatusIntegracao" header="Status Integração"></Column>
                <Column headerStyle="width:110px">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-pencil"
                      class="p-mr-2 p-mb-2 p-button-warning"
                      @click="alterar_plano(slotProps.data, slotProps.index)"
                    ></Button>
                    <Button
                      icon="pi pi-trash"
                      class="p-mb-2 p-button-danger"
                      @click="remover_plano($event, slotProps.index)"
                    ></Button>
                  </template>
                </Column>
              </DataTable>

            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="center-loading" v-if="loading>0">
      <ProgressSpinner />
    </div>

  </BlockUI>

  <OverlayPanel ref="op" :showCloseIcon="true" :dismissable="true" appendTo="body" id="info">
    <strong>TAGS DISPONÍVEIS:</strong><br><br>
    AGUA<br>
    ALUGUEL<br>
    CONDOMINIO<br>
    DANOS_IMOVEL<br>
    GAS<br>
    IPTU<br>
    LUZ<br>
    MULTAS_CONTRATUAIS<br>
    PINTURA_EXTERNA<br>
    PINTURA_INTERNA<br>
    TAXA<br>
    TEMPO_CONTRATO
  </OverlayPanel>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>

export default {
  data() {
    return {
      // 1. Controle de apresentação
      loading: 1,

      // 1.1. Validadores
      fieldsError: [],

      // 2. Chaves Estrangeiras
      ativo_inativo: [
        { nome: "ATIVO", id: "ATIVO" },
        { nome: "INATIVO", id: "INATIVO" },
      ],

      integrada_ativo_inativo: [
        { nome: "ATIVO", id: 1 },
        { nome: "INATIVO", id: 0 },
      ],


      // 3. Dados de formulários

      // 3.1. Informações Gerais

      nome: "",
      taxa: 0,
      prioridade: 0,
      integrada: 0,
      analiseNovo: 1,
      analiseMudancaGarantia: 1,
      status: "ATIVO",

      plano_id: "",
      plano_nome: "",
      plano_coberturas: "",
      plano_formula: "",
      plano_taxa: 0.0,
      plano_status: "ATIVO",
      plano_status_integracao: "ATIVO",
      plano_id_integracao: "",

      planos: [],

      // 4. Dado selecionado para edição

      seguradora: null,
      
    };
  },

  mounted() {
    this.carregarObjetoRota();
  },

  watch: {
    $route() {
      if(this.$route.name == "seguradora")
        this.carregarObjetoRota();
    },

    seguradora: function (val) {
      
      this.limparFormulario();

      if (val != null) {

        // 3. Dados de formulários

        // 3.1. Informações Gerais

        this.nome = val.nome;
        this.taxa = parseFloat(val.taxa);
        this.prioridade = val.prioridade;
        this.status = val.status;
        this.integrada = val.integrada;
        this.analiseNovo = val.analiseNovo;
        this.analiseMudancaGarantia = val.analiseMudancaGarantia;

        if(Array.isArray(val.planos)) {
          for(const p of val.planos) {
            this.planos.push({
              id: p.id,
              nome: p.nome,
              coberturas: p.coberturas,
              taxa: p.taxa,
              status: p.status,
              status_integracao: p.status_integracao,
              formula: p.formula,
              id_integracao: p.id_integracao
            });
          }
        }

      }
    },

  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    carregarObjetoRota() {
      const self = this;

      if (
        typeof this.$route.params.id != "string" ||
        this.$route.params.id == ""
      ) {
        self.loading--;
        return;
      }

      let bloqueio = setInterval(() => {

        if(self.loading == 1) {

          self.$api
            .get("/seguradoras/" + self.$route.params.id)
            .then(function (response) {
              const dados = response.data;
              if (dados.success) { 
                self.seguradora = dados.data;
                self.loading = false;
              } else {
                alert(dados.message);
                self.$router.push({ path: `/seguradoras` });
              }

              self.loading--;

              clearInterval(bloqueio);

            });

        }

      },500);

    },

    limparFormulario() {
      //Limpa as variáveis

      // 1.1. Validadores
      this.fieldsError = [];

      // 3. Dados de formulários

      // 3.1. Informações Gerais

      this.nome = "";
      this.taxa = 0;
      this.prioridade = 0;
      this.status = "ATIVO";
      this.integrada = 0;
      this.analiseNovo = 0;
      this.analiseMudancaGarantia = 0;

      this.plano_id = "";
      this.plano_nome = "";
      this.plano_coberturas = "";
      this.plano_formula = "";
      this.plano_taxa = 0.0;
      this.plano_status = "ATIVO";
      this.plano_id_integracao = "";

      this.planos = [];

    },

    // // // AÇÕES DO USUÁRIO // // //

    gravar_plano() {

      if (this.plano_nome != "" && this.plano_coberturas != "" && this.plano_status != "") {

        const self = this;

        const dados = {
          id: ((this.plano_id == "" || this.plano_id[0] == "_")?"":this.plano_id),
          nome: this.plano_nome,
          status: this.plano_status,
          status_integracao: this.plano_status_integracao,
          taxa: this.plano_taxa,
          coberturas: this.plano_coberturas,
          formula: this.plano_formula,
          id_integracao: this.plano_id_integracao
        };        

        if(this.plano_id) {

          let idx = -1;

          if(this.plano_id[0] == "_")
            idx = parseInt(this.plano_id.substr(1));
          else
            idx = this.planos.findIndex(function (el) {
              return self.plano_id == el.id;
            });

          if(idx == -1) {

            this.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: "Plano não encontrado para atualizar!",
              life: 5000,
            });

          } else {
            this.planos[idx] = dados;
          }

        } else {
          this.planos.push(dados);
        }

        this.cancelar_alteracao_plano();

      } else {
        
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Preencha todas as informações sobre o plano antes de gravar!",
          life: 5000,
        });

      }

    },

    remover_plano(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.planos.splice(index, 1);
        },
      });
    },

    cancelar_alteracao_plano() {

      this.plano_id = "";
      this.plano_nome = "";
      this.plano_coberturas = "";
      this.plano_formula = "";
      this.plano_taxa = 0.0;
      this.plano_status = "ATIVO";
      this.plano_id_integracao = "";

    },

    alterar_plano(plano, idx) {

      if(plano.id != "")
        this.plano_id = plano.id;
      else
        this.plano_id = '_' + idx;
      this.plano_nome = plano.nome;
      this.plano_coberturas = plano.coberturas;
      this.plano_taxa = plano.taxa;
      this.plano_formula = plano.formula;
      this.plano_status = plano.status;
      this.plano_id_integracao = plano.id_integracao;

    },

    criar_alterar() {

      const self = this;

      const criar = (
        typeof this.$route.params.id != "string" ||
        this.$route.params.id == ""
      );

      this.fieldsError = [];

      let dados = {
        "nome": this.nome,
        "taxa": this.taxa,
        "prioridade": this.prioridade,
        "integrada": this.integrada,
        "analiseNovo": this.analiseNovo,
        "analiseMudancaGarantia": this.analiseMudancaGarantia,
        "status": this.status,
        "planos": this.planos,
      };

      if (!criar) {
        dados["_method"] = "PUT";
      }

      this.$api
        .post(
          "/seguradoras" + (!criar ? "/" + this.$route.params.id : ""),
          dados
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            if(typeof response.data.fields != "undefined") {

              const dicionario = {};

              for(const f of response.data.fields) {

                if(typeof dicionario[f] != "undefined") {
                  self.fieldsError.push(dicionario[f]);
                } else {
                  self.fieldsError.push(f);
                }

              }

            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });
    }

  },
};
</script>

<style scoped>
</style>